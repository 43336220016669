<template>
    <div v-if="show">
        <div class="card">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{ $t('title.routes') }}
                                        <span v-if="pagination.total">({{ pagination.total }})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button @click="setOperation('add')" variant="info" size="sm"
                                                      :title="$t('button.title.addNewItem')"
                                                      v-if="$global.hasPermission('routesstore')" v-b-tooltip.hover>
                                                <i class="fe fe-plus"></i> {{ $t('button.addNew') }}
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('routesview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{ $t('button.cancel') }}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="routes-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(is_active)="record">
                            <b-badge v-show="record.item.is_active > 0" variant="success">{{ $t('msc.active') }}
                            </b-badge>
                            <b-badge v-show="record.item.is_active <= 0">{{ $t('msc.de_active') }}</b-badge>
                        </template>
                        <template v-slot:cell(from_location_id)="{item}">
                            <span v-if="item.from_location">
                                <print-ad :item="item.from_location"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(to_location_id)="{item}">
                            <span v-if="item.to_location">
                                <print-ad :item="item.to_location"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(to_zone_id)="{item}">
                            {{(item.to_zone || {}).name}}
                        </template>
                        <template v-slot:cell(from_zones)="{item}">
                            {{_.map(item.from_zones, (item) => item.name).join(', ')}}
                        </template>
                        <template v-slot:cell(to_zones)="{item}">
                            {{_.map(item.to_zones, (item) => item.name).join(', ')}}
                        </template>
                        <template v-slot:cell(type)="{item}">
                            <span v-if="item.type == 1">{{$t('title.locationToLocation')}}</span>
                            <span v-if="item.type == 2">{{$t('title.locationToZone')}}</span>
                            <span v-if="item.type == 3">{{$t('title.zoneToZone')}}</span>
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')" v-if="$global.hasPermission('routesupdate')"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('routesdestroy')">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class=" ml-1"
                                   :title="$t('button.title.deleteItem')"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.routes-table -->
                <div class="routes-operation">
                    <a-drawer
                        placement="right"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationClose"
                        :visible="operation !== null && operation !== 'detail'"
                        :zIndex="10"
                        :title="$t(operationTitle)"
                    >
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 10 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <b-col sm="12">
                                            <b-form-group
                                                :label="$t('input.type')+' *'"
                                                label-for="type"
                                                :invalid-feedback="formErrors.first('type')"
                                            >
                                                <b-form-radio v-model="formFields.type"
                                                              :disabled="!(!formFields.id)"
                                                              name="some-radios" value="1">{{$t('title.locationToLocation')}}
                                                </b-form-radio>
                                                <b-form-radio v-model="formFields.type"
                                                              :disabled="!(!formFields.id)"
                                                              name="some-radios" value="2">{{$t('title.locationToZone')}}
                                                </b-form-radio>
                                                <b-form-radio v-model="formFields.type"
                                                              :disabled="!(!formFields.id)"
                                                              name="some-radios" value="3">{{$t('title.zoneToZone')}}
                                                </b-form-radio>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="12">
                                            <b-form-group
                                                :label="$t('input.name')+' *'"
                                                label-for="name"
                                                :invalid-feedback="formErrors.first('name')"
                                            >
                                                <b-form-input
                                                    id="name"
                                                    v-model="formFields.name"
                                                    type="text"
                                                    :state="((formErrors.has('name') ? false : null))"
                                                    ref="name"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="12" v-if="formFields.type <= 2">
                                            <b-form-group
                                                :label="$t('input.fromLocation')+' *'"
                                                label-for="from_location"
                                                :invalid-feedback="formErrors.first('from_location_id')"
                                            >
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.locations"
                                                    placeholder=""
                                                    v-model="formFields.from_location_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('from_location_id'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="12" v-if="formFields.type == 1">
                                            <b-form-group
                                                :label="$t('input.toLocation')+' *'"
                                                label-for="to_location"
                                                :invalid-feedback="formErrors.first('to_location_id')"
                                            >
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.locations"
                                                    placeholder=""
                                                    v-model="formFields.to_location_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('to_location_id'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="12" v-if="formFields.type == 2">
                                            <b-form-group
                                                :label="$t('input.toZone')+' *'"
                                                label-for="zones"
                                                :invalid-feedback="formErrors.first('to_zone_id')"
                                            >
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.zones"
                                                    placeholder=""
                                                    v-model="formFields.to_zone_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('to_zone_id'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->

                                        <b-col sm="12" v-if="formFields.type == 3">
                                            <b-form-group
                                                    :label="$t('input.fromZones')+' *'"
                                                    label-for="zones"
                                                    :invalid-feedback="formErrors.first('from_zones')"
                                            >
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.zones"
                                                    placeholder=""
                                                    v-model="formFields.from_zones"
                                                    :class="[{'invalid is-invalid': (formErrors.has('from_zones'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="12" v-if="formFields.type == 3">
                                            <b-form-group
                                                :label="$t('input.toZones')+' *'"
                                                label-for="zones"
                                                :invalid-feedback="formErrors.first('to_zones')"
                                            >
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.zones"
                                                    placeholder=""
                                                    v-model="formFields.to_zones"
                                                    :class="[{'invalid is-invalid': (formErrors.has('to_zones'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="12">
                                            <b-form-group
                                                :label="$t('input.description')+' '"
                                                label-for="description"
                                                :invalid-feedback="formErrors.first('description')"
                                            >
                                                <b-form-textarea
                                                    id="description"
                                                    v-model="formFields.description"
                                                    :state="((formErrors.has('description') ? false : null))"
                                                    ref="description"
                                                    @focus="$event.target.select()"
                                                    rows="5"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t('button.save') }}
                                    </b-button>
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{ $t('button.cancel') }}
                                    </b-button>
                                </div>
                            </b-row><!--/b-row-->
                        </form><!--/form-->
                    </a-drawer>
                </div><!--/.routes-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.fromLocation')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.from_location"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.toLocation')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.to_location"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.fromAddedDate')"
                                        label-for="fromDate">
                                        <b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.toAddedDate')"
                                        label-for="toDate">
                                        <b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{ $t('button.close') }}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{ $t('button.reset') }}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{ $t('button.apply') }}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
    </div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'

    const FORM_STATE = {
        name: null,
        from_location_id: null,
        to_location_id: null,
        description: null,
        type: 1,
        to_zone_id: null,
        from_zones: null,
        to_zones: null,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        name: null,
        description: null,
        to_date: null,
        from_location: [],
        to_location: [],
        from_data: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.name'),
            key: 'name',
            sortable: true,
        },
        {
            label: self.$t('column.fromLocation'),
            key: 'from_location_id',
            sortable: true,
            sortKey: 'from_location_id',
        },
        {
            label: self.$t('column.toLocation'),
            key: 'to_location_id',
            sortable: true,
            sortKey: 'to_location_id',
        },
        {
            label: self.$t('column.type'),
            key: 'type',
            sortable: true,
            sortKey: 'type',
        },
        {
            label: self.$t('column.toZone'),
            key: 'to_zone_id',
            sortable: true,
            sortKey: 'to_zone_id',
        },
        {
            label: self.$t('column.fromZones'),
            key: 'from_zones',
            sortable: false,
        },
        {
            label: self.$t('column.toZones'),
            key: 'to_zones',
            sortable: false,
        },
        (self.$global.hasAnyPermission(['routesupdate', 'routesdestroy'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Datepicker,
            Treeselect
        },
        data() {
            return {
                operationTitle: 'title.routes',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'routes',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    locations: [],
                    routes: [],
                    zones: [],
                },
                show: true,
            }
        },
        mounted() {
            this.getLocations();
            this.getRoutes();
            this.getZones();

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.routes')
        },
        methods: {
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addRoute' : 'title.editRoute')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'routes/update' : 'routes/create',
                        method: 'post',
                        data: this.formFields,
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    } else {
                        this.itemAdded()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/routes/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.itemDeleteFailsBecsDependency()
                        return false;
                    }

                    this.itemDeleteFails()
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/routes/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editRoute')
                    const {data} = response
                    const {from_location, to_location, to_zone, from_zones, to_zones} = data
                    delete data.from_location
                    delete data.to_location
                    delete data.to_zone
                    delete data.to_zones
                    delete data.from_zones
                    this.formFields = {
                        ...data,
                        is_active: (data.is_active > 0),
                        to_location_id: (to_location ? to_location.id : null),
                        from_location_id: (from_location ? from_location.id : null),
                        to_zone_id: (to_zone ? to_zone.id : null),
                    }

                    if (data.type === 3) {
                        this.formFields.from_zones = from_zones.map(item => item.id)
                        this.formFields.to_zones = to_zones.map(item => item.id)
                    }
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data

                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getRoutes() {
                try {
                    const response = await request({
                        url: '/dropdowns/routes',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.routes = data

                } catch (e) {
                    this.dropdowns.routes = []
                }
            },
            async getZones() {
                try {
                    const response = await request({
                        url: '/dropdowns/zones',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.zones = data

                } catch (e) {
                    this.dropdowns.zones = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('routesview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            }
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
